import React, { FC, FormEvent, useState, useCallback } from 'react';
import {
  useExperiments,
  useTranslation,
  WidgetProps,
  useBi,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { Button } from 'wix-ui-tpa';
import { FormViewer, FormViewerContext } from '@wix/form-viewer/widget';
import { type FormValues, type FormError } from '@wix/form-viewer';
import settingsParams from '../settingsParams';
import { st, classes } from './Widget.st.css';

export type ControllerProps = {
  // Here, props passed via controller's `setProps` should be defined.
};

const Widget: FC<WidgetProps<ControllerProps>> = (props) => {
  const settings = useSettings();
  const { experiments } = useExperiments();
  const { t } = useTranslation();
  const [guests, setGuests] = useState<string[]>([]);
  const onSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    // @ts-expect-error
    const value = ev.target[0].value;
    // @ts-expect-error
    ev.target[0].value = '';
    setGuests((list) => [value, ...list]);
  };
  return (
    <div
      className={st(classes.root, {})}
      data-hook="VidmantasOnboarding-wrapper"
    >
      <div className={classes.header}>
        <h2 data-hook="app-title">
          {t('app.widget.welcome')} {settings.get(settingsParams.greetingsText)}
        </h2>

        <form onSubmit={onSubmit}>
          <label htmlFor="inp-1">Your name</label>
          <input id="inp-1" type="text" placeholder="Max Payne" />
          <input type="submit" />
        </form>
      </div>
      {/* {This is a dummy experiment. To generate a new experiment,
            check this guide: https://github.com/wix-private/fed-handbook/blob/master/EXPERIMENTS.md#create-experiment} */}

      {guests.map((name) => (
        <h2>{name}</h2>
      ))}
      {experiments.enabled('specs.test.ShouldHideButton') ? null : (
        <Button className={classes.mainButton}>
          {settings.get(settingsParams.buttonText)}
        </Button>
      )}
      <FormWithMe data={props} />
    </div>
  );
};

export default Widget;

interface FormResult {
  values?: FormValues;
  errors?: any[];
}

const FormResultText = (_p: any) => null;
const FormResultModal = (_p: any) => null;

const FormWithMe = ({ data }: any) => {
  const bi = useBi();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({});
  const [formErrors, setFormErrors] = useState<FormError[]>([]);
  const [stepId, setStepId] = useState<string | undefined>(undefined);
  const [result] = useState<FormResult>({});

  const handleFormChange = useCallback((values: FormValues) => {
    setFormValues(values);
  }, []);

  const handleFormValidate = useCallback((errors: FormError[]) => {
    setFormErrors(errors);
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const { i18n } = useTranslation();

  const externalData = {
    challengeId: 'a1950253-eb09-4cf0-8751-f0049cc28356',
    participantId: '70806d42-8f46-4a48-b26e-4a1751f25dc1',
  };

  return (
    <div className={classes.container}>
      <FormViewerContext {...data}>
        <FormViewer
          onChange={handleFormChange}
          onValidate={handleFormValidate}
          values={formValues}
          errors={formErrors}
          i18n={i18n}
          formId={data.formId}
          bi={bi}
          externalData={externalData}
          stepId={stepId}
          onStepIdChange={setStepId}
          style={data.host.style}
        />

        {result.errors && (
          <FormResultText errors={result.errors} handleClick={openModal} />
        )}
        <FormResultModal
          isOpen={isModalOpen}
          closeModal={closeModal}
          values={result.values}
          errors={result.errors}
        />
      </FormViewerContext>
    </div>
  );
};
